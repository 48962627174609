import React from "react"
import Layout from "components/layout"
import Footer from "components/footer"
import SEO from "components/seo"
import { graphql } from "gatsby"
import BlogBase from "components/blog_base"

const BlogInner = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <div className="body">
      <Layout link="blog">
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description}
          keywords={post.frontmatter.description}
        />
        <BlogBase data={post} provider="blog" />
      </Layout>
      <Footer />
    </div>
  )
}

export default BlogInner

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        image {
          childImageSharp {
            fluid(maxWidth: 1075, quality: 72) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        read
      }
    }
  }
`
